import { Container, Tab, TabList, Tabs, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link, Outlet, useLocation } from "react-router-dom";

const FaqIndexPage: FunctionComponent = observer(function FaqIndexPage() {
  const { pathname } = useLocation();

  const tabIndex = pathname === "/faqs/property_managers" ? 1 : 0;

  return (
    <>
      <Header />
      <Container maxW="container.xl">
        <VStack justifyContent="cetner" paddingX="10px" marginTop="40px">
          <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
            Frequently Asked Questions
          </Text>
          <Tabs isFitted w="100%" index={tabIndex} marginTop="40px">
            <TabList>
              <Link to="/faqs/renters" style={{ width: "50%" }}>
                <Tab w="100%">Renters</Tab>
              </Link>
              <Link to="/faqs/property_managers" style={{ width: "50%" }}>
                <Tab w="100%">Property Managers</Tab>
              </Link>
            </TabList>
          </Tabs>

          <Container maxW="container.lg" marginY="60px">
            <Outlet />
          </Container>
        </VStack>
      </Container>
      <Footer />
    </>
  );
});

export default FaqIndexPage;
