import "@fontsource/inter";

import React from "react";
import { createRoot } from "react-dom/client";
import HomeApp from "~/apps/home";
import FlashParser from "~/services/FlashParser";
import FlashStore from "~/stores/FlashStore";

const htmlRoot = document.getElementById("root");
const reactRoot = createRoot(htmlRoot);
const flashes = new FlashParser(
  htmlRoot?.getAttribute("data-flashes") ?? ""
).call();
const flashStore = new FlashStore(flashes);

reactRoot.render(<HomeApp flashStore={flashStore} />);
