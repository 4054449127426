import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useContext, type FunctionComponent } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { BsEnvelope } from "react-icons/bs";
import FlashContext from "~/contexts/FlashContext";
import PublicAxios from "~/services/PublicAxios";

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ModalFormInput {
  name: string;
  email: string;
  message: string;
}

const MessageModal: FunctionComponent<MessageModalProps> = observer(
  function MessageModal({ isOpen, onClose }) {
    const flashStore = useContext(FlashContext);

    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm<ModalFormInput>({});

    const onSubmit: SubmitHandler<ModalFormInput> = (data) => {
      PublicAxios.post("/api/public/contact_forms", {
        contactForm: {
          name: data.name,
          email: data.email,
          message: data.message,
        },
      })
        .then(() => {
          flashStore.addFlashToCurr({
            type: "success",
            message:
              "Thanks for contacting us, a member of the team will reach out.",
          });
          onClose();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          flashStore.addFlashToCurr({
            type: "error",
            message: "Something went wrong, please try again.",
          });
          onClose();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalCloseButton />

          <form onSubmit={handleSubmit(onSubmit) as () => void}>
            <ModalBody>
              <FormControl isInvalid={errors.name !== undefined} pb="24px">
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Your name"
                  {...register("name", {
                    required: "name is required",
                    maxLength: {
                      value: 100,
                      message: "Maximum length of name is 100 characters",
                    },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email !== undefined} pb="24px">
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={BsEnvelope} boxSize={5} />
                  </InputLeftElement>
                  <Input
                    type="email"
                    placeholder="username@example.com"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Email must be in username@domain.com format",
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.message !== undefined} pb="24px">
                <FormLabel>Message</FormLabel>
                <Textarea
                  placeholder="Write a message..."
                  {...register("message", {
                    required: "message is required",
                    maxLength: {
                      value: 1000,
                      message:
                        "Maximum length of the message is 1000 characters",
                    },
                  })}
                  minH="150px"
                  maxH="300px"
                />
                <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isSubmitting}
              >
                Submit
              </Button>
              <Button
                colorScheme="gray"
                variant="ghost"
                isLoading={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

export default MessageModal;
