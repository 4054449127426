import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import { PmFaqs } from "../../services/Faqs";

const PropertyManagersFaqsPage: FunctionComponent = observer(
  function PropertyManagersFaqsPage() {
    return (
      <Accordion allowMultiple>
        {PmFaqs.map((faq) => (
          <AccordionItem
            key={faq.question}
            borderWidth="2px"
            borderStyle="solid"
            marginY="10px"
            border="full"
          >
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Text fontSize="20px" fontWeight="bold">
                  {faq.question}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel py={4}>
              <Text fontSize="16px">{faq.answer}</Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }
);

export default PropertyManagersFaqsPage;
