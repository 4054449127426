import { Container, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Markdown from "react-markdown";
import "github-markdown-css/github-markdown-light.css";
import "./index.css";

const TermsOfServiceMarkdown = `
**Terms of Use**

Thank you for visiting the website of **Cosign, Inc.’s** (“**Company**” or “**We**” or “**Us**” or "**Cosign**") at [www.rentwithcosign.com](http://www.rentwithcosign.com) (the “**Site**”). These Terms of Use (the “**Agreement**”) are provided to inform users about our policies regarding the terms and conditions for using this Site. Your use of this Site is conditional upon your acceptance, without modification, of this Agreement by you as a user of this Site (“**You**” or “**User**”).

IF YOU AGREE TO THIS AGREEMENT BY CHECKING THE "**I AGREE**" BOX, YOU ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT AS PRESENTED TO YOU ON THE DATE OF YOUR ASSENT (THE “**EFFECTIVE DATE**”). COSIGN DOES NOT ACCEPT ANY CHANGES (ADDITIONS OR DELETIONS) TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU WILL NOT HAVE ANY RIGHT TO USE THIS SITE. YOU SHOULD DOWNLOAD AND PRINT THIS AGREEMENT FOR YOUR RECORDS.

By visiting this Site, you agree to the following:

1. **Definitions.** In addition to other defined terms in this Agreement, the following terms have these meanings:
2. “**Cosign Server**” or “**Server**” refers to the computer software or hardware that serves and hosts the Site to users over the Internet; and
3. “**User(s)**” includes but is not limited to the following: an applicant who needs a co-signer to qualify for a property management company's vacancy; a property management professional who has signed and agreed to the terms of the acceptance agreement or has referred an applicant to the Cosign site; a property management professional who accesses the Cosign site to start a claim on a renter who defaulted on rental payments; property management companies who have not agreed or signed the acceptance but access the Cosign site for informational purposes only.
4. **Access; Conditions to Use of the Site.**
    1. Subject to all the terms and conditions of this Agreement, Cosign grants User a non-exclusive, revocable, and limited right to access and use the Site in strict compliance with this Agreement (“**Access Right**”) or any other agreement User has with Cosign. Cosign reserves the right to suspend or revoke this Access Right at its discretion without notice. Additionally, Cosign has the right to change, suspend, or discontinue any aspects or features of the Site at any time, including the availability of any content or features on the Site. This Access Right granted to User under this Agreement will immediately terminate upon the expiration, cancellation, or termination of this Agreement for any reason.
    2. Users are responsible for obtaining and maintaining any equipment or ancillary services needed to connect to or access the Site, including Internet connections, modems, hardware, software, and long-distance or local telephone service.
    3. As a condition of using the Site, Users agree not to use the Site for any unlawful purpose or for any purpose prohibited by this Agreement. Users may not use the Site in any manner that could damage, disable, overburden, or impair the Site or Cosign Servers, or interfere with any other party's use and enjoyment of the Site. Users may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
    4. Despite any assistance that Cosign may provide, Users assume sole responsibility for uploading and updating any of their Content (if any) available through the Site.
    5. Users further agree as follows:
        1. Without limiting the generality of the foregoing, Users agree to the following: (a) Users will not upload, distribute, or publish through the Site any data, information, messages, text, works, material, or other content, including personal identifiable information related to the User (collectively, "**Content**") that is unlawful, libelous, defamatory, invasive of privacy or publicity rights, harassing, threatening, abusive, inflammatory, obscene, or otherwise objectionable; (b) Users will not upload or transmit any Content that would violate the rights of any party, would constitute or encourage a criminal offense, or would create liability or violate any local, state, federal, or international law; (c) Users will not upload or transmit any Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party anywhere; (d) Users will not impersonate any person or entity or misrepresent their affiliation with a person or entity; (e) Users will not distribute or publish unsolicited promotions, advertising, or solicitations for any goods, services, or money, including junk mail and junk email; (f) Users will not use the Site for purposes not authorized by Cosign; and (g) Users will not use the Site for any illegal purpose or any fraudulent scheme or transaction.
        2. Users hereby grant Cosign a perpetual, worldwide, transferable, fully paid-up right to use their Content to: (i) provide the User with any services contemplated by the Site and under this Agreement, including other uses normally intended for Users, (ii) assist or coordinate with any claims arising out of the use of the Site, including claims involving property management professionals; and (iii) assist in their claim; and for any other lawful purpose in carrying out Cosign's business.
    6. Without limiting any other provisions herein, Users agree to the following: (a) Users are prohibited from violating or attempting to violate the security of the Site or the Cosign Server, including, without limitation, (i) accessing data not intended for such User or logging into a server or account which the User is not authorized to access; (ii) attempting to probe, scan, or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (iii) attempting to interfere with service to any user, host, or network; or (iv) sending unsolicited email, including promotions and/or advertising of products or services; (b) any violations of system or network security (including the Site or the Cosign Server) may result in civil or criminal liability; and (c) Cosign has the right to investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting Users who are involved in such violations. Cosign also reserves the right to cooperate with any and all law enforcement agencies, including complying with warrants, court orders, and subpoenas and disclosing to law enforcement agencies any information about any User and anything a User does with respect to the Site. By using the Site, Users authorize Cosign to take such action.
    7. If the Site, now or in the future, allows Users to upload, transmit, or otherwise contribute any Content to the Site, Users represent and warrant to Cosign that they have the lawful right to distribute and reproduce such Content. Users are solely responsible for their conduct (and the conduct of their users) while using the Site, including all Content in any folders or web pages (if any), or through any other transactions or interactions Users generate, transmit, or maintain via the Site. Cosign takes no responsibility for any such online distribution or publication by Users or any other party. Cosign cannot and will not review every message or other Content that Users or any other party may generate or post, and Cosign is not responsible for the Content thereof.
    8. In addition to any other right to terminate this Agreement, Cosign has the absolute right to immediately terminate, without warning, any account which it believes, in its sole discretion, breaches any of the provisions of this Section.
5. **Copyrights and Other Intellectual Property Rights; Reservation of Rights.**
    1. Cosign’s policy is to respect the copyright and intellectual property rights of others. Cosign has the absolute right to (i) immediately terminate, without warning, all rights (including all Access Rights) of any Users who appear to infringe upon the copyright or intellectual property rights of others, and (ii) remove any Content from the Site that may infringe the copyright or other intellectual property rights of any third party.
    2. This Agreement shall not transfer any rights in any intellectual property from Cosign to any Users. Cosign or its licensors shall solely own all inventions, patents, trademarks/service marks, logos, images, graphics, content, reports, analysis, data, formulae, processes, techniques, software, website designs, all other copyrights, and all other intellectual property provided in, made available by using, or otherwise contained in, the Cosign Site and provided in furtherance of this Agreement (collectively, “**Cosign IP Assets**”).
    3. Cosign IP Assets may not be used by Users without prior written permission from Cosign, and then only with proper acknowledgment. Any rights not expressly granted herein to Users are reserved to Cosign. Additionally, Users' Access Right is subject to the following conditions: (i) Users shall not modify, disassemble, decompile or reverse translate or create derivative works from any of the Cosign IP Assets or otherwise attempt to derive any source code of the same or let any third party do the same; (ii) no copyrighted material, content, or any other Cosign IP Assets may be downloaded, modified, copied, displayed, transferred, distributed, sold, published, broadcast, or otherwise used except as expressly stated either in such materials or in this notice without the express prior written permission of Cosign (which Cosign may or may not grant in its sole discretion); (iii) Users shall not remove, alter, cover or obscure any copyright notices or other proprietary rights notices of Cosign or any other party placed on or embedded in the Cosign IP Assets and shall otherwise retain all such notices on all copies of the same; and (iv) use of any of the Cosign IP Assets is prohibited unless Users are authorized Users in good standing. Unauthorized use is a violation of copyright and other intellectual property rights and is actionable
    4. User agrees to keep strictly confidential all Cosign IP Assets that have not been made publicly available by Cosign. User also acknowledges and agrees that the terms and conditions of this provision shall survive the cancellation, expiration, or termination of this Agreement for any reason.
6. **Privacy**.
    1. User agrees that: (i) if the User has any Content or any login or password associated with this Site, then the User is solely responsible for maintaining the confidentiality of such information; and (ii) if the User has any login or password associated with this Site, then the User (a) is solely responsible for all uses of its login and password regardless of whether these uses are authorized by User, and (b) User will immediately notify Cosign of any unauthorized use of the User’s login and password.
    2. The Privacy Statement for the Site is hereby incorporated into this Agreement by reference, and User agrees to comply with it at all times.
7. **Indemnity.**
    1. As a User, you will indemnify and hold Cosign, its parents, subsidiaries, affiliates, officers, and employees harmless, including costs and attorneys' fees, from any claim or demand made by any third party due to or arising out of your access to the Site, use of the Site, violation of this Agreement by you, or the infringement by you or any third party using your account, of any intellectual property or other right of any person or entity.
8. **Site Resources**.
    1. The Site may provide a variety of information, data, facts, and features (collectively, “**Site Resources**”) for the User’s benefit. While Cosign endeavors to provide the most current and accurate Site Resources possible, the User acknowledges and agrees that (i) the Site Resources may be general in nature and may not apply to specific factual circumstances; and (ii) the Site Resources may contain errors and should not be relied upon as a substitution for independent investigation by the User.
    2. ALL SITE RESOURCES ARE PROVIDED “**AS IS**” AND SITE RESOURCES AVAILABLE THROUGH THIS SITE MAY BE SUPERSEDED AND/OR MAY INCLUDE INACCURACIES. ALSO, WHERE A DOCUMENT CONTAINED AS A SITE RESOURCE IS OBTAINED FROM ANOTHER SOURCE, THEN THE OTHER SOURCE (AND ITS WEBSITE) TAKES PRECEDENCE. COSIGN MAY MAKE IMPROVEMENTS AND/OR CHANGES TO THIS SITE, SITE RESOURCES, AND ITS COSIGN SERVERS AT ANY TIME.
    3. Each User agrees that if We provide You with access to other types of information, that information is provided to You “**AS IS**”. Without limiting the foregoing in any way, You agree that: (i) Cosign is not providing You with any advice, including any advice regarding credit repair, credit advice, etc.; (ii) We are not responsible for the information provided on the Site or any other sites that We provide You with access to, including without limitation any data about property listing information, etc.; and (iii) We are not lawyers and, therefore, We are not responsible for any documents that You sign with your property management professional.
9. **Links to Third-Party Sites** As a convenience to Users, the Site may now, or in the future, provide links to other Internet websites that are not owned by Cosign and are not under our control (“**Third Party Websites**”). Cosign does not control these Third Party Websites and is not responsible for the content included in them, including, without limitation, any subsequent links contained within a linked website, or any changes or updates to a linked website. Any reference from the Cosign Site to any entity, product, service, or information does not constitute an endorsement or recommendation by Cosign. No Third Party Website is authorized to make any representations or warranties on our behalf. Your visit to any Third Party Websites is subject to the terms and conditions of such Third Party Websites, and not this Site’s Agreement. Users should refer to each Third Party Website’s specific terms.
10. **Disclaimer of Warranties; Disclaimer of Liability**.
    1. COSIGN MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OR ACCURACY OF THE SITE RESOURCES CONTAINED IN THIS SITE. ALL SITE RESOURCES ARE PROVIDED “**AS IS**” WITHOUT WARRANTY OF ANY KIND. COSIGN HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. COSIGN DOES NOT WARRANT THAT THE SITE AND THE SITE RESOURCES ON THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE COSIGN SERVER MAKING THIS SITE AVAILABLE IS FREE OF COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS.
    2. COSIGN SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM INCONVENIENCE, OR LOSS OF USE, RESOURCES, OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE SITE, OR SITE RESOURCES MADE AVAILABLE THROUGH THIS SITE, OR ANY THIRD-PARTY WEBSITES, EVEN IF COSIGN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
11. **Changes in these Terms of Use**. Once you begin to use your Access Rights to the Site, Cosign may modify this Agreement at any time and you will thereafter be bound by the version of this Agreement that is in effect at the time you visit the Site. Any use of the Site by you thereafter shall be deemed to constitute acceptance by you of the amendments.
12. **Termination**. In the event you or anyone else using your privileges violates the terms of this Agreement (as determined in Cosign’s discretion), Cosign reserves the right to take any action it deems appropriate, including, but not limited to, termination of this Agreement, including all Access Rights (as defined in Section 2.1 above). In addition to any other right of Cosign to terminate this Agreement, Cosign further reserves the right, without notice, at any time, in its sole discretion, and for any reason, to terminate this Agreement, including all Access Rights. Cosign is not required to provide mail or web page forwarding at termination.
13. **Miscellaneous Provisions**
    1. **Governing Law**. This Agreement shall be construed and controlled by the laws of the State of Delaware. The laws of the State of Delaware will govern any dispute arising from the terms of this Agreement or a breach of this Agreement. User agrees to personal jurisdiction by the state and federal courts sitting in the State of Delaware in New Castle County.
    2. **Notices**. Except as otherwise expressly provided in this Agreement, any communications between the parties, or notices to be given hereunder, will be given in writing by personal delivery, express courier, facsimile, or United States Postal Service, postage prepaid, or by email to User at any address (or facsimile or email) provided to Cosign, or to Cosign at the official address (or official facsimile or official email address) given for Cosign’s corporate headquarters, or to such other addresses or numbers as Cosign may hereafter indicate pursuant to this Section. Unless otherwise provided in this Agreement, any communication or notice so addressed and mailed will be deemed to be given five (5) days after mailing; provided, however, any communication or notice delivered: (i) by facsimile will be deemed to be given when the transmitting machine generates a receipt of a successful transmission of the notice; or (ii) by email will be deemed to be given when the email has been generated and sent by the sender. Unless otherwise provided in this Agreement, any communication or notice given by personal delivery will be deemed to be given immediately upon such delivery, provided such delivery is made to the person indicated below.
    3. **Remedies**. User acknowledges that monetary damages may not be a sufficient remedy for unauthorized use of Cosign’s Site, and therefore User agrees that Cosign shall be entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed proper by a court or arbitration panel of competent jurisdiction without necessity of posting a bond and without having to plead and prove lack of an adequate remedy at law.
    4. **Attorney Fees**. If any suit or action is filed by any party to enforce this Agreement or otherwise with respect to the subject matter of this Agreement, the prevailing party shall be entitled to recover reasonable attorney fees incurred in preparation or in prosecution or defense of such suit or action as fixed by the trial court and, if any appeal is taken from the decision of the trial court, reasonable attorney fees as fixed by the appellate court.
    5. **Binding Effect; No Assignment by Client; Permissible Assignment by Cosign**. This Agreement shall be binding upon and inure to the benefit of each party’s respective successors and lawful assigns; provided, however, that User may not assign this Agreement, in whole or in part. Any purported assignment in violation of this Section shall be void. Cosign shall have the right to assign this Agreement, or any part of it, in its sole discretion to any party, and all covenants and agreements hereunder shall inure to the benefit of and be enforceable by such successors and assigns.
    6. **Entire Agreement; Modification; Waiver**. This Agreement, including any attachments and exhibits attached hereto, constitutes the entire agreement between the parties with respect to the subject matter hereof. None of the provisions of this Agreement shall be deemed to have been waived by any act or acquiescence on the part of Cosign, its agents, or employees, but only by an instrument in writing signed by an authorized employee of Cosign. No waiver of any provision of this Agreement shall constitute a waiver of any other provision(s) or of the same provision on another occasion. If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law, then the remainder of the Agreement shall continue in effect.

If you have any questions about this Agreement, please contact us at <support@rentwithcosign.com>.
`;

const TermsOfServicePage: FunctionComponent = observer(
  function TermsOfServicePage() {
    return (
      <>
        <Header />
        <Container maxW="container.xl">
          <VStack justifyContent="cetner" paddingX="10px">
            <Container maxW="container.lg">
              <div className="markdown-body">
                <Markdown>{TermsOfServiceMarkdown}</Markdown>
              </div>
            </Container>
          </VStack>
        </Container>
        <Footer />
      </>
    );
  }
);

export default TermsOfServicePage;
