import React, { type FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import Application from "~/components/Application";
import type FlashStore from "~/stores/FlashStore";
import Routes from "./Routes";

interface HomeAppProps {
  flashStore: FlashStore;
}

const HomeApp: FunctionComponent<HomeAppProps> = observer(function HomeApp({
  flashStore,
}: HomeAppProps) {
  return (
    <Application authenticated={false} flashStore={flashStore}>
      <Routes />
    </Application>
  );
});

export default HomeApp;
