import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

const PublicAxios = applyCaseMiddleware(
  axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
);

export default PublicAxios;
