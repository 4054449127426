import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import { RenterFaqs } from "../../services/Faqs";

const RentersFaqsPage: FunctionComponent = observer(function RentersFaqsPage() {
  return (
    <Accordion allowMultiple>
      {RenterFaqs.map((faq) => (
        <AccordionItem
          key={faq.question}
          borderWidth="2px"
          borderStyle="solid"
          marginY="10px"
          border="full"
        >
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Text fontSize="20px" fontWeight="bold">
                {faq.question}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel py={4}>
            <Text fontSize="16px">{faq.answer}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
});

export default RentersFaqsPage;
