import {
  Box,
  Button,
  Container,
  HStack,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import FlashAlerts from "~/components/FlashAlerts";
import PrimaryLogo from "~/components/Logo/PrimaryLogo";
import MessageModal from "./MessageModal";

interface HeaderProps {
  backgroundColor?: string;
}

const Header: FunctionComponent<HeaderProps> = observer(function Header({
  backgroundColor,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  backgroundColor ??= "white";

  return (
    <Box w="100vw" backgroundColor={backgroundColor}>
      <Container maxW="container.xl">
        <FlashAlerts />
        <HStack w="100%" h="72px">
          <a href="/">
            <PrimaryLogo />
          </a>
          <Spacer />
          <a href="/users/sign_in">
            <Button colorScheme="gray">Sign In</Button>
          </a>
          <Button colorScheme="blue" onClick={onOpen}>
            Learn More
          </Button>
        </HStack>

        <MessageModal isOpen={isOpen} onClose={onClose} />
      </Container>
    </Box>
  );
});

export default Header;
